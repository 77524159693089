/******************************************* NAVBAR **********************************************/

.navMenu {
    background-color: #1e88e5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    box-shadow: 0px 4px 10px -1px #989898;
}

.Link {
    text-decoration: none;
    color: white;
    list-style: none;
    list-style-type: none;
}

.navLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 0;
    padding: 0;
}
