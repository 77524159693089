/******************************************* BODY, HTML, MAIN **********************************************/
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 17px;
}

main {
    display: flex;
    flex-wrap: wrap;
    height: 90vh;
}

/******************************************* TYPING BAR **********************************************/

.typingBar {
    height: 7%;
    width: 100%;
}

.searchIcon {
    position: absolute;
    margin-top: 12px;
    margin-left: 1vw;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}
.icon {
    padding: 10px;
    min-width: 40px;
}

.inputMessage {
    text-indent: 10px;
    padding: 0.6em 3vw;
    font-size: 1em;
    width: 100%;
    height: 100%;
    display: inline-block;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px 0px #989898;
}

.inputMessage::placeholder {
    color: #999;
}

.redMessage {
    text-indent: 10px;
    padding: 0.6em 3vw;
    font-size: 1em;
    width: 100%;
    color: red;
    height: 100%;
    display: inline-block;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px 0px #989898;
}

/******************************************* SECTION MESSAGES **********************************************/

.sectionMessages {
    display: flex;
    height: 100%;
    width: 80%;
    flex-wrap: wrap;
}

.messagesContainer {
    width: 100%;
    height: 93%;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

.divMessages {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 1em;
}

.messageBubble {
    width: 40vw;
    padding: 1em 1em;
    word-wrap: break-word;
    position: relative;
    background: #64b5f6;
    border-radius: 0.4em;
    margin-left: 2%;
}

.messageBubble:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-right-color: #64b5f6;
    border-left: 0;
    border-bottom: 0;
    margin-top: -8px;
    margin-left: -16px;
}

.noMessages {
    background-color: red;
}

.myMessages {
    width: 40vw;
    padding: 1em 1em;
    margin-left: 38%;
    word-wrap: break-word;
    position: relative;
    background: #8bc34a;
    border-radius: 0.4em;
}

.myMessages:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-left-color: #8bc34a;
    border-right: 0;
    border-bottom: 0;
    margin-top: -8px;
    margin-right: -16px;
}

.divAvatar {
    width: 5vw;
    word-wrap: break-word;
    text-align: center;
}

.divAlignAvatar {
    display: flex;
    justify-content: center;
}

.userName {
    margin: 0;
    text-align: center;
}

.contentMessage {
    padding-left: 5%;
    margin: 0;
    align-self: center;
}

.date {
    margin-top: 0;
    margin-left: 60%;
}

.dateFromPeople {
    width: 100%;
    margin-left: 26%;
    margin-top: 0;
}

.myMessagesContainer {
    width: 93%;
}

.dateContainer {
    width: 7%;
    display: flex;
    justify-content: center;
}

/******************************************* MEDIA QUERIES FOR RESPONSIVE **********************************************/
@media screen and (max-width: 1280px) {
    .messageBubble {
        margin-left: 8%;
    }

    .myMessages {
        margin-left: 30%;
    }
}

@media screen and (max-width: 650px) {
    .myMessages {
        margin-left: 20%;
    }
}

@media screen and (max-width: 450px) {
    .myMessages {
        margin-left: 10%;
    }
}

@media screen and (min-width: 1620px) {
    .myMessages {
        margin-left: 40%;
    }
}
