/******************************************* ASIDE **********************************************/

aside {
    width: 20%;
    display: flex;
    flex-direction: column;
    background-color: #f1eeee;
    box-shadow: 2px 0px 10px -1px #989898;
    z-index: 2;
}

.userBadge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.textPuce {
    margin-left: 10%;
    width: 100%;
}

.divTextPuce {
    word-break: break-all;
}

.divBadge {
    width: 100%;
}

section h4 {
    text-align: center;
}

.connectesList {
    padding-left: 5%;
}

.scrollDiv {
    width: 100%;
    max-height: 60.5vh;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.selectMessages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.selectMessages h4 {
    width: 100%;
}

/******************************************* MEDIA QUERIES FOR RESPONSIVE **********************************************/
@media screen and (max-width: 650px) {
    .scrollDiv {
        max-height: 55.5vh;
    }
}

@media screen and (max-width: 450px) {
    .scrollDiv {
        max-height: 50.5vh;
    }
}
